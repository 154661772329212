// Here you can add other styles
body {
  /* hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* hide scrollbar for chrome, safari and opera */
body::-webkit-scrollbar {
  display: none;
}
